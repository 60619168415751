<template>
  <div id="upload-payrolls">
    <h2 class="content-title" >Separar nóminas</h2>
    <div class="content-container" :class="loadingContent ? '' : 'visible'" >
      <q-card class="content-card card-4">
        <p class="content-card__title">Seleccionar PDF</p>
        <div class="select-pdf-container">
          <label class="select-pdf">
            <span class="select-pdf__title">{{ pdfSelected  ? pdfName : 'Selecciona o arrastra el archivo' }}</span>
            <div class="select-pdf__icon">
              <FileTextIcon v-if="pdfSelected" size="1x" />
              <UploadIcon v-else size="1x" />
            </div>
            <input type="file" accept="application/pdf" @change="processPdf" class="select-pdf__input form-control-file" id="pdf-input">
            <q-btn :loading="btnSelectLoading" class="select-pdf__btn" color="primary">
              {{ pdfSelected  ? 'Cambiar' : 'Seleccionar' }}
            </q-btn>
          </label>
        </div>
      </q-card>
      <q-card class="content-card card-8 card-error">
        <p class="content-card__title">Nóminas no válidas: {{ invalidTD.length }}</p>
        <div class="invalid-payrolls">
          <div class="invalid-payrolls__table">
              <q-table
                no-data-label="No hay ninguna nómina no válida"
                :data="invalidTD"
                :columns="invalidTC"
                row-key="name"
              ></q-table>
          </div>
        </div>
      </q-card>
      <q-card class="content-card card-success">
        <p class="content-card__title">Nóminas válidas: {{ validTD.length }}</p>
        <div class="valid-payrolls">
          <q-table
            no-data-label="No hay ninguna nómina válida"
            :data="validTD"
            :columns="validTC"
            row-key="name"
          ></q-table>
          <q-btn 
            v-if="validTD.length"
            :loading="btnUploadLoading" 
            @click="onUpload" 
            class="valid-payrolls__btn" 
            color="primary">
              Subir nóminas
          </q-btn>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { UploadIcon, FileTextIcon } from "vue-feather-icons"
import dictionary from '../../mixins/dictionary'

export default {
  mixins: [dictionary],
  data() {
    return {
      loadingContent: true,
      btnSelectLoading: false,
      btnUploadLoading: false,
      pdfData: null,
      pdfName: '',
      pdfSelected: false,
      validTC: [
        { name: 'type', label: 'Tipo', field: 'type', align: 'center', format: val => {
          return val == 'payroll' ? 'Nómina' : 'Paga extra' 
        }},
        { name: 'num_ss', label: 'Nº S.S.', field: 'num_ss', align: 'center' },
        { name: 'fullname', label: 'Nombre', field: 'fullname', align: 'center' },
        { name: 'school_name', label: 'Escuela', field: 'school_name', align: 'center' },
        { name: 'start_date', label: 'Fecha', field: 'start_date', align: 'center' },
        { name: 'gross_salary', label: 'Bruto', field: 'gross_salary', align: 'center' },
        { name: 'net_salary', label: 'Neto', field: 'net_salary', align: 'center' }
      ],
      invalidTC: [
        { name: 'num_ss', label: 'Nº S.S.', field: 'num_ss', align: 'center' },
        { name: 'start_date', label: 'Fecha', field: 'start_date', align: 'center' },
        { name: 'gross_salary', label: 'Bruto', field: 'gross_salary', align: 'center' },
        { name: 'net_salary', label: 'Neto', field: 'net_salary', align: 'center' },
        { name: 'reason', label: 'Motivo', field: 'reason', align: 'center', format: val => {
          return 'El empleado no está en el sistema'
        }}
      ],
      validTD: [],
      invalidTD: []
    }
  },

  computed: {
    ...mapGetters('auth', [
      'userAuth'
    ])
  },

  components: {
    UploadIcon,
    FileTextIcon
  },

  methods: {
    ...mapActions('payrolls', [
      'getPayrolls',
      'getPendingPayrolls'
    ]),

    async processPdf(event) {
      let env = this
      env.btnSelectLoading = true
      env.pdfData = event.target.files[0]
      env.pdfName = this.pdfData.name
      let form = new FormData()
      form.append('action', 'split')
      form.append('document', env.pdfData)
      form.append('token', env.userAuth)
      await axios
      .post('https://www.api.babyteca.es/api/payrolls', form)
      .then(function (response) {
        env.pdfSelected = true
        env.btnSelectLoading = false
        if (response.data.status == 'ok') {
          env.filterPayrolls(response.data.msg)
          env.notify('success', 'ok_split')
        } else {
          env.notify('error', 'ko_split')
        }
      })
      .catch(function () {
        env.btnSelectLoading = false
        env.notify('error', 'ko_split')
      })
    },

    async onUpload(){
      let env = this
      env.btnUploadLoading = true
      let form = new FormData()
      form.append('action', 'upload')
      form.append('payrolls', JSON.stringify(env.validTD))
      form.append('token', env.userAuth)
      await axios
      .post('https://www.api.babyteca.es/api/payrolls', form)
      .then(function (response) {
        if (response.data.status == 'ok') {
          env.notify('success', 'ok_upload')
          env.pdfData = null
          env.pdfName = ''
          env.pdfSelected = false
          env.validTD = []
          env.invalidTD = []
          env.updateLayout()
        } else {
          env.notify('error', 'ko_upload')
        }
        env.btnUploadLoading = false
      })
      .catch(function () {
        env.btnUploadLoading = false
        env.notify('error', 'ko_upload')
      })
    },

    filterPayrolls(payrolls) {
      this.invalidTD = payrolls.filter(payroll => payroll.employee.length == 0)
      this.validTD = payrolls.filter(payroll => payroll.employee.length != 0)
    },

    async updateLayout() {
      let form = new FormData()
      form.append('token', this.userAuth)
      await this.getPayrolls(form)
      await this.getPendingPayrolls(form)
    }
  },

  async mounted() {
    let env = this
    env.loadingContent = true
    setTimeout(function(){
      env.loadingContent = false
    }, 100)
  }
}
</script>
